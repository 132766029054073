import logo from './logo.svg';
import './App.css';
import Page from './page';
import ScrollToTop from "react-scroll-up";

function App() {
  return (
    <div className="App">
      <Page />
      <ScrollToTop showUnder={160} style={{ transitionDuration: "0.6s" }}>
        <span className="back-to-top"></span>
      </ScrollToTop>
    </div>
  );
}

export default App;
