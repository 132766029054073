import React, { useEffect } from "react";
import { CookieSetting } from "cookiesetting-component";

const CookieSettingWrapper = () => {
  useEffect(() => {
    // Dynamically add Bootstrap 5 stylesheet
    const bootstrap5 = document.createElement("link");
    bootstrap5.rel = "stylesheet";
    bootstrap5.href =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
    bootstrap5.id = "bootstrap5";
    document.head.appendChild(bootstrap5);

    return () => {
      // Remove Bootstrap 5 stylesheet when component unmounts
      document.getElementById("bootstrap5")?.remove();
    };
  }, []);

  return (
    <div className="cookiesetting-component">
      <CookieSetting
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </div>
  );
};

export default CookieSettingWrapper;