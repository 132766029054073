import React from 'react'
import { Image, Container, Row, Col } from "react-bootstrap";
import "../css/header.css";
import BrokerLogo from "../images/RR-BrokerLogo.svg";
import MortgageLendersLogo from "../images/MortgageLendersLogo.svg";
import DialWhite from "../images/DialWhite.svg";
import { Zoom } from 'react-awesome-reveal';
import UserWayAccessibilityMenu from "../components/UserWayAccessibilityMenu/index";

const Header = () => {
    return <>
        <div className='header'>
            <Container className='section-container'>
                <Row className='header-row'>
                    <Col className="USlogo" lg={4} md={6}>
                        <a href='https://www.usmortgagelenders.com/'
                            target="_blank" rel="noreferrer noopener"
                            title='US Mortgage Lenders'
                        >
                            <Image
                                className="broker-logo"
                                width="600"
                                height="117"
                                src={MortgageLendersLogo}
                                fluid
                                alt="US Mortgage Lenders"
                            />
                        </a>
                    </Col>
                    <Col lg={4} md={6} className=' d-flex header-contact-col'>
                        <Zoom triggerOnce fraction={0.6}>
                            <div className='header-contact'>
                                <a className='header-tel telephone poppins-bold' href="tel:+19542149470" >
                                    <div className="dial-white-wrapper">
                                        <Image
                                            className="dial-white"
                                            width="30"
                                            height="30"
                                            src={DialWhite}
                                            fluid
                                            alt="dial logo"
                                        />
                                    </div>
                                    (954) 214-9470
                                </a>
                            </div>
                            <div className="desktop-AccessibilityMenu-show">
        <UserWayAccessibilityMenu color="black" width={40} height={40}/>
</div>
                        </Zoom>
                       
                    </Col>
                   
                </Row>
                <div className="mobile-AccessibilityMenu-show">
       <UserWayAccessibilityMenu color="black" width={40} height={40}/>
</div>
            </Container>

            
        </div>
    </>
}

export default Header