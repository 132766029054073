import React, { useEffect, useState } from 'react';
import { Col, Button, Form, Spinner, Modal, Container, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import "../css/form.css";
import "../css/consent.css";

import axios from "axios";
import Consent from './Consent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//create object of date-fns module.
const datefns = require('date-fns');

const ContactForm = ({
    isFormSubmitSuccess
}) => {
    //constants variables
    const SHORT_DATE_FORMAT = 'MM/dd/yyyy'
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [scheduleMeetingDate, setScheduleMeetingDate] = useState(new Date());
    const [scheduleMeetingLastDate, setScheduleMeetingLastDate] = useState(new Date());
    const [bestTimeToContact, setBestTimeToContact] = useState("Anytime");
    const [yourNeeds, setYourNeeds] = useState('');
    const [callBackBtnClicked, setCallBackBtnClicked] = useState(false);

    const [scheduleMeetingFormLoading, setScheduleMeetingFormLoading] = useState(false);
    const [callBackFormLoading, setCallBackFormLoading] = useState(false);
    const [showTYModal, setShowTYModal] = useState(false);
    const [showFailModal, setShowFailModal] = useState(false);
    const [honeyPot, setHoneyPot] = useState(false);
    const [agreed, setAgreed] = useState(true)

    const setFocusOnFormView = () => {
        const frmContact = document.getElementById('frmContact');
        frmContact.scrollIntoView({block: "start"}); 
    }
    
    const handleConsentClick = (e, flag) => {
        if (flag) {
            setAgreed(true)
        } else {
            setAgreed(false)
        }
    }

    function trim(theStr) {
        return theStr.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    }
    
    function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
        var retVal = '';
        var okChars = '0123456789';
        var tmpVal = trim(inVal);
        var curChar = '';
        var isDotFound = false;
        
        if (tmpVal !== '') {
            for (var i = 0; i < tmpVal.length; i++) {
                curChar = tmpVal.charAt(i);
            
                if (okChars.indexOf(curChar) !== -1) {
                    if (!isDotFound && curChar === '.' && isDouble) {
                        isDotFound = true;
                        retVal = retVal + '.';
                    }
            
                    if (retVal === '' && removeCharsFromPrefix.indexOf(curChar) !== -1) {
                    //Do nothing
                    } else {
                        retVal += curChar;
                    }
                }
            }
        }
        
        return retVal;
    }

    const handleTelephoneNumber = (evt) => {
        //Save Phone number in local storage for Apply
        var tmpVal = trim(getNumbers(evt.currentTarget.value, false, '01'));
        var FirstThreeDigit = tmpVal.substring(0, 3);
        var FirstSixDigit = tmpVal.substring(0, 6);
    
        /* Below Pattern will check numbers between below ranges
            456, 555, 900,
            100, 101, ..., 199
            211, 311, ..., 911  ***811 removed
            370, 371, ..., 379
            960, 961, ..., 969
            800, 801, ..., 855  ***removed
            866, 877, 888, 800855
            */
        var patt =
          /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
        var patt2 = /^800855$/;
        if (patt.test(FirstThreeDigit)) {
          evt.currentTarget.value = '';
          return;
        } else if (patt2.test(FirstSixDigit)) {
          evt.currentTarget.value = '';
          return;
        }
    
        var curChar = '';
        var areaCode = '';
        var firstThree = '';
        var lastFour = '';
        var extension = '';
    
        if (tmpVal !== '') {
          for (var i = 0; i < tmpVal.length; i++) {
            curChar = tmpVal.charAt(i);
    
            if (i < 3) areaCode += curChar;
            if (i < 6 && i > 2) firstThree += curChar;
            if (i < 10 && i > 5) lastFour += curChar;
            if (i > 9) extension += curChar;
          }
        }
    
        var retVal = '';
    
        /* Do not put the parentesis if we only have the first three digits of the phone number.
            Once we are past that then start formatting the phone number. This was the main issue
            why the phone number couldn't be blanked out in it's entirety.*/
        if (areaCode !== '' && firstThree === '') retVal += areaCode;
        else if (areaCode !== '' && firstThree !== '') retVal += '(' + areaCode + ')';
    
        if (firstThree !== '') retVal += ' ' + firstThree;
    
        if (lastFour !== '') retVal += '-' + lastFour;
    
        if (extension !== '') retVal += ' x' + extension;
        else setPhoneNumber(retVal);
    };

    useEffect(() => {
        const lastMeetingScheduleDate = datefns.addMonths(scheduleMeetingDate, 2);
        setScheduleMeetingLastDate(lastMeetingScheduleDate);
        setFocusOnFormView();
    }, [])

    /**
     * @author Pratik Kajrolkar 
     * @date 05/25/2022
     * 
     * This function will format date in the format passed
     * in the argument
     * e.g: formatDate(20210518, 'MM/dd/yyyy') => 05/18/2021
     *
     * @param {Date} date the date that needs to be formatted
     * @param {String} dateFormat the format at which date will be converted
     * @returns {String} formatted date string
     */
    function formatDate(date, dateFormat) {
        let formatedDate = null
        try {
            formatedDate = datefns.format(new Date(date), dateFormat)
        } catch (e) {
            console.log("Unable to format date: " + e)
        }

        return formatedDate
    }

    function handleTYClose() {
        setEmailAddress("");
        setFirstName("");
        setPhoneNumber("");
        setScheduleMeetingDate(new Date());
        setBestTimeToContact("Anytime");
        setYourNeeds("");
        setShowTYModal(false);
        setValidated(false);
    }

    function handleFailClose() {
        setEmailAddress("");
        setFirstName("");
        setPhoneNumber("");
        setScheduleMeetingDate(new Date());
        setBestTimeToContact("Anytime");
        setYourNeeds("");
        setShowFailModal(false);
        setValidated(false);
    } 

    const sendEmailToRosie = async(emailRequestData) => {
        const requestForCallBack = emailRequestData.requestedCallBack;
        let emailSubjectLine = "";
        let emailBody = "<div>Hi Rosie,</div>" + "<div style='padding-top:10px;'>";
        if (requestForCallBack) {
            emailSubjectLine = emailRequestData.userName + " - Requesting a Call Back";
            emailBody = emailBody + "A user is requesting a call back from you. Below are the details:";
        } else {
            emailSubjectLine = emailRequestData.userName + " - Requested to Schedule Meeting";
            emailBody = emailBody + "A user is trying to schedule a meeting with you. Below are the details:";
        }

        emailBody = emailBody + 
            "<br/>" +
            "Name - " + emailRequestData.userName + "<br/>" +
            "Email - " + emailRequestData.userEmail + "<br/>" +
            "Telephone - " + emailRequestData.userPhone + "<br/>" +
            "Schedule Date - " + emailRequestData.meetingDate + "<br/>" +
            "Best time to contact - " + emailRequestData.contactTimeForUser  + "<br/>" +
            "Comment - " + emailRequestData.comment +
        "</div>";

        if (emailSubjectLine && emailBody) {
            const requestBody = {
                toEmailAddress: [
                    process.env.REACT_APP_ROSIE_EMAIL_ADDRESS
                ],
                emailSubjectLine: emailSubjectLine,
                emailBody: emailBody
            }

            await axios.post(process.env.REACT_APP_SEND_EMAL_SES, requestBody,
              {
                headers: { 'Content-Type': 'application/json' },
              }
            )
            .then((response) => {
              if (response.data) {
                //do nothing.
              } else {
                setShowFailModal(true);
              }
            })
            .catch((error) => {
                setShowFailModal(true);
                console.log(error);
            });
        }
    }

    const sendEmailToUser = async(emailRequestData) => {
        const requestForCallBack = emailRequestData.requestedCallBack;
        let emailSubjectLine = "";
        let emailBody = "<div>Hi ";
        if (emailRequestData.userFName) {
            emailBody = emailBody + emailRequestData.userFName;
        } else {
            emailBody = emailBody + "Folks";
        }
        emailBody = emailBody + ",</div>" +
        "<div style='padding-top:10px;'>" + 
        "Thanks for reaching out to " + process.env.REACT_APP_TITLE + "</div>";
        if (requestForCallBack) {
            emailSubjectLine = emailRequestData.userName + " - Call Back Request Recorded";
            emailBody = emailBody + "<div style='padding-top:10px;'>" +
            "Your Request for Call Back has been recorded for " + emailRequestData.meetingDate +
            "</div><div style='padding-top:10px;'>She will reach out to you shortly.</div>";

        } else {
            emailSubjectLine = emailRequestData.userName + " - Request for Meeting is Scheduled";
            emailBody = emailBody + "<div style='padding-top:10px;'>" +
            "Your Request for Meeting has been recorded for " + emailRequestData.meetingDate +
            "</div><div style='padding-top:10px;'>She will reach out to you shortly to schedule the date and time of the meeting.</div>";
        }

        if (emailSubjectLine && emailBody) {
            const requestBody = {
                toEmailAddress: [
                    emailRequestData.userEmail
                ],
                emailSubjectLine: emailSubjectLine,
                emailBody: emailBody
            }

            await axios.post(process.env.REACT_APP_SEND_EMAL_SES, requestBody,
              {
                headers: { 'Content-Type': 'application/json' },
              }
            )
            .then((response) => {
              if (response.data) {
                setShowTYModal(true);
              } else {
                setShowFailModal(true);
              }
            })
            .catch((error) => {
                setShowFailModal(true);
                console.log(error);
            });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        let frmContactForm = document.getElementById('frmContact');
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            if (!honeyPot) {
                if (callBackBtnClicked) {
                    setCallBackFormLoading(true);
                } else {
                    setScheduleMeetingFormLoading(true);
                }
                let userFullName = firstName;
                let userFirstName = "";
                let userLastName = "";
                if (userFullName.includes(" ")) {
                    let name = userFullName.split(" ");
                    userFirstName = name[0];
                    userLastName = name.slice(1).join(" ");
                } else {
                    userFirstName = firstName;
                }

                let schdMeetingDate = new Date();
                if (scheduleMeetingDate) {
                    schdMeetingDate = scheduleMeetingDate;
                }

                schdMeetingDate = formatDate(schdMeetingDate, SHORT_DATE_FORMAT);
                const comment = yourNeeds ? yourNeeds : "NA";

                const emailRequestBody = {
                    requestedCallBack: callBackBtnClicked,
                    userName: userFullName,
                    userFName: userFirstName,
                    userEmail: emailAddress,
                    userPhone: phoneNumber,
                    meetingDate: schdMeetingDate,
                    contactTimeForUser: bestTimeToContact,
                    comment: comment
                }

                await sendEmailToRosie(emailRequestBody);
                await sendEmailToUser(emailRequestBody);

                frmContactForm.classList.remove("was-validated");
                frmContactForm.reset();
                setCallBackFormLoading(false);
                setScheduleMeetingFormLoading(false);
            }
        }
        setValidated(true);
    };

    // jsx
    return (
        <Container className="form-container">
            <div className="form-titles-section">
            </div>
            <>
            <Form
                className="need-validation"
                id="frmContact"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                method="POST"
                autoComplete="off"
            >
                <Row>
                    <Form.Group as={Col} sm={12} controlId="firstName" className="form-group">
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Name"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            The Name field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row> 
                <Row>
                    <Form.Group as={Col} sm={12} controlId="emailAddress">
                        <Form.Control
                            type="text"
                            value={emailAddress}
                            pattern="^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-])+.\.([A-Za-z]{2,4})$"
                            onChange={(e) => setEmailAddress(e.target.value)}
                            placeholder="Email Address"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            The Email Address field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} sm={12} controlId="phoneNumber">
                        <Form.Control
                            type="text"
                            value={phoneNumber}
                            minLength={14}
                            maxLength={21}
                            pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                            onChange={handleTelephoneNumber}
                            placeholder="Telephone Number"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            The Telephone Number field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>  
                <Row>
                    <Form.Group as={Col} sm={12} controlId="scheduleMeetingDate">
                        <DatePicker
                            className='datepicker-input'
                            placeholderText='Date'
                            selected={scheduleMeetingDate}
                            onChange={(date) => setScheduleMeetingDate(date)}
                            includeDateIntervals={[
                                { start: new Date(), end: scheduleMeetingLastDate },
                            ]}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select the Date as per your preference.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} sm={12} controlId="bestTimeToContact">
                        <Row>
                            <Col md={7} sm={12} className="ps-0">
                                <Form.Label>Best time to contact me?</Form.Label>
                            </Col>
                            <Col md={5} sm={12}>
                                <Form.Select value={bestTimeToContact}
                                    placeholder="Best time to contact me?"
                                    onChange={(e) => {setBestTimeToContact(e.target.value);
                                }}>
                                    <option value="08:00 AM to 10:00 AM">08:00 AM to 10:00 AM</option>
                                    <option value="10:00 AM to 12:00 PM">10:00 AM to 12:00 PM</option>
                                    <option value="12:00 PM to 02:00 PM">12:00 PM to 02:00 PM</option>
                                    <option value="02:00 PM to 04:00 PM">02:00 PM to 04:00 PM</option>
                                    <option value="04:00 PM to 06:00 PM">04:00 PM to 06:00 PM</option>
                                    <option value="Weekend">Weekend</option>
                                    <option value="Anytime">Anytime</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} sm={12} controlId="yourNeeds">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={yourNeeds}
                            onChange={(e) => setYourNeeds(e.target.value)}
                            placeholder="Your Needs"
                        />
                    </Form.Group>
                </Row>
                <Row className="consent-div consent-v2">
                    <Consent
                        agreed={agreed}
                        handleConsentClick={handleConsentClick}
                    />
                </Row>
                <Col md={10} className='btn-row-parent-col pt-4'>
                    <Row className='btn-row'>
                        <Col lg={6} className="meeting-btn-col pt-1 btnSubmit-Col text-center">
                            <Button className="btnSubmit" type="submit"
                                onClick={() => setCallBackBtnClicked(false)}>
                                {scheduleMeetingFormLoading ? (
                                    <div className="smlsLoader">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>{` Please wait...`}</span>
                                    </div>
                                ) : (
                                    "Schedule a Meeting"
                                )}
                            </Button>
                        </Col>
                        <Col lg={6} className="callback-btn-col pt-1 btnSubmit-Col text-center">
                            <Button className="btnSubmit" type="submit"
                                onClick={() => setCallBackBtnClicked(true)}>
                                {callBackFormLoading ? (
                                    <div className="smlsLoader">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>{` Please wait...`}</span>
                                    </div>
                                ) : (
                                    "Call back"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Form>
            </>
            <Modal
                size="lg"
                centered
                show={showTYModal}
                onHide={handleTYClose}
                backdrop="static"
                className="aqua-theme-wrapper"
            >
                <Modal.Header className="aqua-theme-title-section" closeButton>
                    <Modal.Title className="modal-title-header text-center py-2">
                        Thank You!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="modal-text-content mb-3">
                        Thank you for contacting US Mortgage Lenders. We will
                        respond to your message as soon as possible.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="close-btn" onClick={handleTYClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                centered
                show={showFailModal}
                onHide={handleFailClose}
                backdrop="static"
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <p className="modal-text-content mb-3">
                        Due to technical difficulties your request could not be processed.
                        Please contact <a href="mailto:Rosariomortgage@hotmail.com">Rosariomortgage@hotmail.com</a>.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="close-btn" onClick={handleFailClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}



export default ContactForm;
