import React from 'react'
import styled from 'styled-components'
import { Col, Row, Form } from 'react-bootstrap'

const StyleWrapper = styled.div`
.consentDesc p::before{
    display: none !important;
}

.checkboxConsent label{
    line-height: 1 !important;
    text-align: left;
    display: block;
    margin-bottom: 3px;
    margin-top: 3px;
    font-size: 11px;
    margin-left: 20px;
}

.checkboxConsent .form-check {
        padding-left: 0 !important;
        margin-top: 15px;
}

.checkboxConsent .form-check-input{
    margin-left: 0 !important;
    left: 0;
    cursor: pointer;
}

.checkboxConsent.consent-text {
    display: flex;
    align-items: center;
    margin-top: 0
}

.form-check-input {
    width: 15px;
    height: 15px;
}

.checkboxConsent.consent-text.form-check .invalid-feedback,
.checkboxConsent.consent-text.form-check .valid-feedback {
    display:none;
}

@media screen and (max-width: 699px) {
    .checkboxConsent label {
        line-height: 1.5 !important;
    }

    .form-check{
        margin-top: 5px !important;
    }

    .form-check-input {
        width: 15px
        height: 15px
    }
}
`

export const Consent = ({
    agreed,
    handleConsentClick
}) => {
    return (
        <StyleWrapper>
            <div className="consentDesc">

                <p className="consent-title">
                    {/* mt-2 mb-1 */}
                    <strong>Phone Solicitation Consent</strong>
                </p>

                <p className="m-0 consent-desc" id="consent-desc-id">
                By clicking I CONSENT below, you expressly consent to US Mortgage Lenders making (or allowing to be made on its behalf) a phone call, text message, or voicemail transmission to the phone number you entered above for the purpose of soliciting your purchase of consumer goods or services (including using an automated system for the selection or dialing of phone numbers, the playing of a recorded message when a connection is made, or the transmission of a pre-recorded voicemail), even if that phone number is a on a national, state, or internal Do Not Call Registry. You are not required to click on I CONSENT below (directly or indirectly) or agree to receive such phone calls, text messages, or voicemail transmissions as a condition of obtaining any goods or services from US Mortgage Lenders or its affiliates.
                </p>
                <div className='ps-2'>
                    <Row className='consent-chkbox-row'>
                        <Col className='px-0 ideal-input' xl={2} lg={3} md={4} sm={4} xs={4}>
                            <Form.Group className="consent-grp" controlId="consentChkBox" 
                                onClick={e => handleConsentClick(e, true)}>
                                <Form.Check type="checkbox" label="I Consent" data-type="agree" value={agreed} checked={agreed} />
                            </Form.Group>
                        </Col>
                        <Col className='px-0' xl={5} lg={5} md={5} sm={6} xs={6}>
                            <Form.Group className="consent-grp" controlId="consentFalseChkBox" 
                                onClick={e => handleConsentClick(e, false)}>
                                <Form.Check type="checkbox" label="I DO NOT Consent" data-type="disagree" checked={agreed === false} value={!agreed} />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <p className='terms-div'>
                By pressing Schedule a Meeting or Call back, you also agree to US Mortgage Lender's <a href='https://www.usmortgagelenders.com/privacy-policy/' target="_blank" rel="noreferrer noopener" title='Privacy Policy'>Privacy Policy</a>, which you may access by clicking on the policy. By communicating with US Mortgage Lenders by phone, you consent to phone calls being recorded and monitored.
                </p>
            </div>
        </StyleWrapper>
    )
}

export default Consent